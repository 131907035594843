import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { IonToolbar, IonTextarea, IonFabButton, IonIcon } from '@ionic/react';
import { sendOutline } from 'ionicons/icons';

import { useI18n } from '@echtech/iron-core';
import styled from 'styled-components';

interface ChatMessageInputProps {
  onSubmit?: (v: string) => void;
  disabled?: boolean;
  value?: string;
}

export type ChatMessageInputRef = {
  reset: () => void;
} | null;

// FIXME: Revisar el forwardRef ahora que vienen los cambios a React
const ChatMessageInput = forwardRef<ChatMessageInputRef, ChatMessageInputProps>(
  (
    { onSubmit, disabled = false, value }: ChatMessageInputProps,
    ref
  ): JSX.Element => {
    const areaRef = useRef<HTMLIonTextareaElement | null>(null);
    const [areaValue, setAreaValue] = useState(value);
    const { t } = useI18n();

    useImperativeHandle(ref, () => ({
      reset: () => {
        setAreaValue('');
      },
    }));

    return (
      <IonToolbar color="none">
        <Wrapper>
          <InputWrapper>
            <CustomTextarea
              aria-label={t('message')}
              disabled={disabled}
              placeholder={t('message')}
              autoGrow
              rows={1}
              value={areaValue}
              ref={areaRef}
              onBlur={() => setAreaValue(areaRef.current?.value?.trim() || '')}
            />
          </InputWrapper>
          <IonFabButton
            color="success"
            disabled={disabled}
            className="ion-align-self-end"
            onClick={() => onSubmit?.(areaRef.current?.value?.trim() || '')}
          >
            <IonIcon icon={sendOutline} />
          </IonFabButton>
        </Wrapper>
      </IonToolbar>
    );
  }
);

ChatMessageInput.displayName = 'MessageInput';

const Wrapper = styled.div`
  padding: 0em 1em;
  display: flex;
  gap: 0.5em;
  margin-bottom: 0.5em;
  ion-fab-button {
    width: 3.5em;
    height: 3em;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  border-radius: 1.875em;
  background-color: rgba(var(--ion-color-primary-rgb), 0.15);
  display: flex;
  align-items: center;
`;

const CustomTextarea = styled(IonTextarea)`
  --padding-top: 0.5em;
  --padding-start: 1em;
  --padding-end: 1em;
  --padding-bottom: 0.5em;

  --highlight-color-focused: none;
  min-height: auto;
`;

export default ChatMessageInput;
